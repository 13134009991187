export const fetchUserDetails = async (userId) => {
  if (!userId) {
      throw new Error('User ID is not defined');
  }

  const response = await fetch(`https://api.ghsdginnovations.org/api/users/${userId}`);
  
  if (!response.ok) {
      throw new Error('Failed to fetch user details');
  }

  return await response.json();
};