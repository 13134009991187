import React, { useState, useEffect } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, FormControl, Select, MenuItem, Grid, Paper, TablePagination } from '@mui/material';
import axios from 'axios';
import Layout from './layout';
import PieChart from './pieChart';
import GhanaMap from './map';
import { fetchUserDetails } from '../../hooks/fetchUserDetails';
import useUserInnovation from '../../hooks/userInnovations';
import swal from 'sweetalert';

const Dashboard = () => {
  const [listOfInnovations] = useUserInnovation();
  const [pendingInnovations, setPendingInnovations] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [userRole, setUserRole] = useState('');
  const [stats, setStats] = useState({
    approvedCount: 0,
    rejectedCount: 0,
    pendingCount: 0,
  });
  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  useEffect(() => {
    const currentUserId = localStorage.getItem('userId'); // Get current user's ID from localStorage

    const fetchAllUserDetails = async () => {
      const details = {};
      for (const innovation of listOfInnovations) {
        if (innovation.userId && !details[innovation.userId]) {
          try {
            details[innovation.userId] = await fetchUserDetails(innovation.userId);
          } catch (error) {
            console.error(`Error fetching details for user ${innovation.userId}:`, error);
          }
        }

        // Set the role of the current user if the ID matches
        if (innovation.userId === currentUserId) {
          setUserRole(details[innovation.userId]?.role);
        }
      }
      setUserDetails(details);
    };

    fetchAllUserDetails();
  }, [listOfInnovations]);

  useEffect(() => {
    const fetchPendingInnovations = async () => {
      if (userRole === "partner") return; // Exclude partners from viewing pending innovations

      try {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get('https://api.ghsdginnovations.org/api/admin/innovations', config);
        const pendingInnovations = response.data.filter(innovation =>
          !innovation.approved && !innovation.rejected && !innovation.archived
        );
        setPendingInnovations(pendingInnovations);
      } catch (error) {
        console.error('Error fetching pending innovations:', error);
      }
    };

    fetchPendingInnovations();
  }, [userRole]);

  const handleAction = async (id, action) => {
    const token = localStorage.getItem('token');
    const endpoint = `https://api.ghsdginnovations.org/api/subAdmin/innovations/${id}/${action}`;

    try {
      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to ${action} the innovation`);
      }

      swal("Success", `Innovation ${action}d successfully!`, "success");

      const updatedPendingInnovations = pendingInnovations.filter(innovation => innovation._id !== id);
      setPendingInnovations(updatedPendingInnovations);
    } catch (error) {
      swal("Error", `Error ${action}ing innovation: ${error.message}`, "error");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedInnovations = pendingInnovations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Layout>
      <Box marginTop='150px' marginBottom='100px'>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Innovations by Sectors
            </Typography>
            <PieChart />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Innovations by Regions
            </Typography>
            <GhanaMap />
          </Grid>
        </Grid>
      </Box>

      {userRole !== 'partner' && (
        <Box marginTop="64px" marginBottom="20px" paddingBottom="20px" overflowY="scroll" maxHeight="calc(100vh - 124px)">
          {pendingInnovations.length === 0 ? (
            <Typography variant="h6" sx={{ color: '#0468B1', textAlign: 'center', padding: '16px' }}>
              No pending innovations.
            </Typography>
          ) : (
            <TableContainer component={Paper} elevation={1}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#0468B1' }}>
                    <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
                      Innovation Name
                    </TableCell>
                    <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
                      Submitted by
                    </TableCell>
                    <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
                      Innovator
                    </TableCell>
                    <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
                      Status
                    </TableCell>
                    <TableCell sx={{ color: '#fff', fontWeight: 'bold', padding: '12px', fontSize: '16px', borderBottom: '2px solid #004b8d', textTransform: 'uppercase' }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedInnovations.map((innovation) => (
                    <TableRow key={innovation._id}>
                      <TableCell component="th" scope="row">
                        <Typography sx={{ color: '#0468B1' }}>
                          {innovation.innovationName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {userDetails[innovation.userId]?.fullName || 'Unknown'}
                      </TableCell>
                      <TableCell>
                        {innovation.firstName && innovation.lastName
                          ? `${innovation.firstName} ${innovation.lastName}`
                          : userDetails[innovation.userId]?.fullName || 'Unknown'}
                      </TableCell>
                      <TableCell>
                        <span style={{ color: 'orange' }}>Pending</span>
                      </TableCell>
                      <TableCell>
                        <FormControl>
                          <Select
                            value=""
                            onChange={(event) => handleAction(innovation._id, event.target.value)}
                            displayEmpty
                          >
                            <MenuItem value="" disabled>Select Action</MenuItem>
                            <MenuItem value="approve">Approve</MenuItem>
                            <MenuItem value="reject">Reject</MenuItem>
                            <MenuItem value="archive">Archive</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={pendingInnovations.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Box>
      )}
    </Layout>
  );
};

export default Dashboard;
