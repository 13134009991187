import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import AnimatedTheme from '@amcharts/amcharts5/themes/Animated'; // Correct import

// Sector data with ids
const sectors = [
  { name: "Education", id: "66543b924a6cefd9e30c728a" },
  { name: "Food and Agriculture", id: "66543b9a4a6cefd9e30c728c" },
  { name: "Health", id: "668714724c9d5509b640651d" },
  { name: "Energy and Climate", id: "668714844c9d5509b640651f" },
  { name: "Fashion and Arts", id: "668714944c9d5509b6406521" },
  { name: "Youth & Women Empowerment", id: "66d5d5165683409f145ae9b7" },
  { name: "Technology", id: "66d5d5275683409f145ae9b9" },
  { name: "Financial Inclusion", id: "66d5d5345683409f145ae9bb" },
  { name: "Water and Sanitation", id: "66d5d5405683409f145ae9bd" }
];

const PieChart = () => {
  const [data, setData] = useState([]);
  const chartRef = useRef(null);

  useEffect(() => {
    // Fetch data from all endpoints
    const fetchData = async () => {
      try {
        const results = await Promise.all(
          sectors.map((sector) =>
            axios.get(`https://api.ghsdginnovations.org/api/innovations/getTotalBySector/${sector.id}`)
          )
        );
        const formattedData = results.map((result, index) => ({
          name: sectors[index].name,
          value: result.data.totalInnovations, // Use 'totalInnovations' from API response
        }));
        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length === 0) return;

    // Initialize chart
    const root = am5.Root.new(chartRef.current);

    root.setThemes([
      AnimatedTheme.new(root) // Correct usage of the theme
    ]);

    const chart = root.container.children.push(am5percent.PieChart.new(root, {}));

    const series = chart.series.push(am5percent.PieSeries.new(root, {
      name: "Sectors",
      valueField: "value",
      categoryField: "name"
    }));

    series.data.setAll(data);

    // Remove category names and percentages from the chart labels
    series.labels.template.set("visible", false); // Hide labels (names)
    series.ticks.template.set("visible", false); // Hide tick marks (lines)

    // Keep tooltips showing sector names and values
    series.slices.template.set("tooltipText", "{category}: {value}");

    // Cleanup on unmount
    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <div>
      <div ref={chartRef} style={{ width: "100%", height: "300px" }}></div>
    </div>
  );
};

export default PieChart;
