import axios from "axios";
import { api } from "../urlConfig";

import { useState, useEffect } from 'react';

const useUserInnovation = () => {
  const [innovations, setInnovations] = useState([]);

  useEffect(() => {
    const fetchInnovations = async () => {
      const response = await fetch('https://api.ghsdginnovations.org/api/innovations');
      const data = await response.json();
      setInnovations(data);
    };

    fetchInnovations();
  }, []);

  return [innovations];
};

export default useUserInnovation;
